<template>
  <header class="header" data-t="m-header">
    <div class="menu">
      <button v-if="withBackButton" class="menu-button" @click="goBack">
        <StIcon name="chevron-left" :size="22" />
      </button>
      <button
        v-if="withMenuButton"
        class="menu-button"
        data-t="burger-menu-bkpu"
        @click="openMenu"
      >
        <StIcon name="menu-burger-mobile" :size="24" />
      </button>
      <button
        v-if="withSearchButton"
        class="menu-button"
        data-t="search-icon-zjef"
        @click="openSearch"
      >
        <StIcon name="search" :size="22" />
      </button>
      <button v-if="withCloseButton" class="menu-button" @click="goBack">
        <StIcon name="cross-large" :size="22" />
      </button>
      <HeaderBonuses
        mobile
        :counter="bonusesCount"
        @click="handleBonusesClick"
      />
    </div>
    <NuxtI18nLink to="/mobile" class="logo" data-t="logo">
      <img
        v-if="isDefaultHeaderColor"
        src="./images/m-logo.svg"
        alt="logotype"
        width="36"
      />
      <img v-else src="./images/m-logo-white.svg" alt="logotype" width="36" />
    </NuxtI18nLink>
    <div class="actions">
      <ClientOnly v-if="isAuthenticated">
        <MUserActions />
      </ClientOnly>
      <div v-else class="login">
        <StButton
          size="m"
          data-t="login-button"
          replace
          :type="isDefaultHeaderColor ? 'gray' : 'ghost'"
          :label="t('header.loginButton')"
          :to="{ query: { modal: 'login' } }"
        />
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import { useBonusesCountStore } from '@st/bonuses/stores/useBonusesCountStore'
import { useUserStore } from '@st/user/stores/useUserStore'
import HeaderBonuses from './parts/HeaderBonuses/HeaderBonuses.vue'
import MUserActions from './parts/MUserActions.vue'

defineProps<{
  withMenuButton?: boolean
  withSearchButton?: boolean
  withBackButton?: boolean
  withCloseButton?: boolean
}>()

const { t } = useI18n()
const { goBack } = useRouterBack()
const { isAuthenticated } = storeToRefs(useUserStore())
const { openMenu, headerColor, isDefaultHeaderColor } = useMobileLayout()

const router = useRouter()
function openSearch() {
  router.replace({ query: { modal: 'search' } })
}

const { bonusesCount } = storeToRefs(useBonusesCountStore())

function handleBonusesClick() {
  router.replace({ query: { modal: 'bonuses' } })
}
</script>

<style scoped>
.header {
  position: sticky;
  z-index: 100;
  top: 0;
  right: 0;
  left: 0;

  display: flex;
  align-items: center;

  height: 48px;
  padding-right: var(--spacing-050);
  padding-left: var(--spacing-050);

  background-color: v-bind(headerColor);
}

.menu {
  display: flex;
  align-items: center;
}

.actions,
.menu {
  flex: 1 1 0;
  min-width: 0;
}

.actions {
  display: flex;
  justify-content: flex-end;
}

.balance {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-050);
  align-items: flex-end;
}

.menu-button {
  all: unset;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: var(--spacing-100);
}

.login {
  margin-right: var(--spacing-100);
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
